import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import "./ModalComp1.css"

//
import Modal1Img from './Modal1Img.webp'


// This is Modal 1
export default function ModalComp(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="my-modal"
    >
      <Modal.Header closeButton>
       
      </Modal.Header>
      <Modal.Body>
        {/* <h4>Centered Modal</h4>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p> */}
        
        
        <Row>
            <Col sm={12} lg={6} > <img src={Modal1Img} className="img-responsive Modal-img" ></img> </Col>
            <Col className='ModalTextCol'>
            {/* <Row><h3 style={{color:"black",textAlign:"center"}} className="ModalText">I am Product</h3></Row> */}
            <br></br>
            <Row><h5 style={{color:"black",textAlign:"center"}} className="ModalText">I help students score at their highest potential for class 11th and 12th for both, State and CBSE boards. All the subjects are covered with the help of additional study material. My expertise lies in ECO, IP, IB and IT. </h5></Row>
            <br></br>
            {/* <Row><h6 style={{color:"black",textAlign:"center"}} className="ModalText">test not knowing what is to be added pppppppppppp</h6> </Row> */}
            </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={props.onHide}>Close</Button> */}
      </Modal.Footer>
    </Modal>
  );
}

