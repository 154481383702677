import "./GetInTouchComp.css"

export default function ContactUsComp(){

        return(
            
                <div  id="GetInTouchDiv" >
                    <h1 id="GetInTouchText" >Get in Touch</h1>
                </div>
            )

}