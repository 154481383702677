import "./PopularMasks.css";

//React Bootstrap
import { Row, Col, Card, Button } from "react-bootstrap";

import { useState } from "react";
import React from "react";

//Image
import MaskMan from "./MaskMan.jpg";
import CoachingImg from '../PopularMasks/card-1.jpg';
import CoachingImage from './CoachingImage.jpg';
import MathsImg from '../PopularMasks/maths-6.jpg';
import CodingImg from '../PopularMasks/card-4.jpg';
import DataAnaly from '../PopularMasks/card-3-3.jpg'

//import Modals
import ModalComp1 from "../ModalComp1/ModalComp1";
import ModalComp2 from "../ModalComp2/ModalComp2";
import ModalComp3 from "../ModalComp3/ModalComp3";
import ModalComp4 from "../ModalComp4/ModalComp4";

export default function PopularMasks() {
  const [styles1, setStyle1] = useState({ display: "none" });
  const [style11, setStyle11] = useState({ display: "block" });
  // const [style12,setStyle12] =useState({display:"none"});

  const [styles2, setStyle2] = useState({ display: "none" });
  const [style21, setStyle21] = useState({ display: "block" });
  // const [style22,setStyle22] =useState({display:"none"});

  const [styles3, setStyle3] = useState({ display: "none" });
  const [style31, setStyle31] = useState({ display: "block" });
  // const [style32,setStyle32] =useState({display:"none"});

  const [styles4, setStyle4] = useState({ display: "none" });
  const [style41, setStyle41] = useState({ display: "block" });
  // const [style42,setStyle42] =useState({display:"none"});

  //Modals
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  const [modalShow4, setModalShow4] = React.useState(false);

  return (
    <div>
      <ModalComp1 show={modalShow1} onHide={() => setModalShow1(false)} />
      <ModalComp2 show={modalShow2} onHide={() => setModalShow2(false)} />
      <ModalComp3 show={modalShow3} onHide={() => setModalShow3(false)} />
      <ModalComp4 show={modalShow4} onHide={() => setModalShow4(false)} />
      <h3 id="PopularMasksHeader">How can I help you?</h3>
      <Row>
        <Col lg={3} sm={12} className="MaskCard">
          <Card className="card"
            onMouseEnter={(e) => {
              setStyle1({ display: "block" });
              // setStyle11({ display: "none" });
              // setStyle12({ display: "block" });
            }}
            onMouseLeave={(e) => {
              setStyle1({ display: "none" });
              // setStyle11({ display: "block" });
              // setStyle12({ display: "none" });
            }}


          >
            <Card.Img className="card-img" variant="top" src={CoachingImg} style={style11} />
            {/* <Card.Img variant="top" src={MaskMan1} style={style12}/> */}
              <Button
                className="QuickViewButton"
                id="quick1"
                variant="light"
                size="lg"
                width="100%"
                borderRadius="0"
                style={styles1}
                onClick={() => setModalShow1(true)}
              >
                Quick View
              </Button>
            <Card.Body>
              <Card.Title className="CardTitle">
                Coaching for ECO, IP, IB, and IT for classes 11 and 12
              </Card.Title>
              {/* <Card.Text className="CardText">Rs 100</Card.Text> */}
            </Card.Body>
            
          </Card>
        </Col>

        <Col lg={3} sm={12} className="MaskCard">
          <Card
            onMouseEnter={(e) => {
              setStyle2({ display: "block" });
              // setStyle21({ display: "none" });
              // setStyle22({ display: "block" });
            }}
            onMouseLeave={(e) => {
              setStyle2({ display: "none" });
              // setStyle21({ display: "block" });
              // setStyle22({ display: "none" });
            }}
          >
            <Card.Img className="card-img" id="img-long" variant="top" src={MathsImg} style={style21} />
            {/* <Card.Img variant="top" src={MaskMan1} style={style22}/> */}
            <Card.Body>
              <Card.Title className="CardTitle" id="long1">
                Academic help and consultation with Mathematics and Science.
              </Card.Title>
              {/* <Card.Text className="CardText">Rs 100</Card.Text> */}
            </Card.Body>
            <Button
              className="QuickViewButton"
              id="quick2"
              variant="light"
              size="lg"
              width="100%"
              borderRadius="0"
              style={styles2}
              onClick={() => setModalShow2(true)}
            >
              Quick View
            </Button>
          </Card>
        </Col>
        <Col lg={3} sm={12} className="MaskCard">
          <Card
            onMouseEnter={(e) => {
              setStyle3({ display: "block" });
              // setStyle31({ display: "none" });
              // setStyle32({ display: "block" });
            }}
            onMouseLeave={(e) => {
              setStyle3({ display: "none" });
              // setStyle31({ display: "block" });
              // setStyle32({ display: "none" });
            }}
          >
            <Card.Img className="card-img" variant="top" src={DataAnaly} style={style31} />
            {/* <Card.Img variant="top" src={MaskMan1} style={style32}/> */}
            <Card.Body>
              <Card.Title className="CardTitle" id="long2">
                Freelancing In Data Science and data analytics, Mathematics and
                computing.
              </Card.Title>
              {/* <Card.Text className="CardText">Rs 100</Card.Text> */}
            </Card.Body>
            <Button
              className="QuickViewButton"
              id="quick3"
              variant="light"
              size="lg"
              width="100%"
              borderRadius="0"
              style={styles3}
              onClick={() => setModalShow3(true)}
            >
              Quick View
            </Button>
          </Card>
        </Col>

        <Col lg={3} sm={12} className="MaskCard">
          <Card
            onMouseEnter={(e) => {
              setStyle4({ display: "block" });
              // setStyle41({ display: "none" });
              // setStyle42({ display: "block" });
            }}
            onMouseLeave={(e) => {
              setStyle4({ display: "none" });
              // setStyle41({ display: "block" });
              // setStyle42({ display: "none" });
            }}
          >
            <Card.Img className="card-img" variant="top" src={CodingImg} style={style41} />
            {/* <Card.Img variant="top" src={MaskMan1} style={style42}/> */}
            <Card.Body>
              <Card.Title className="CardTitle">
                Training for Data analytics and Data Science
              </Card.Title>
              {/* <Card.Text className="CardText">Rs 100</Card.Text> */}
            </Card.Body>
            <Button
              className="QuickViewButton"
              id="quick4"
              variant="light"
              size="lg"
              width="100%"
              borderRadius="0"
              style={styles4}
              onClick={() => setModalShow4(true)}
            >
              Quick View
            </Button>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
