import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import "./ModalComp2.css"

//
import Modal2Img from './Modal2Img.webp'


// This is Modal 2
export default function ModalComp2(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="my-modal"
    >
      <Modal.Header closeButton>
       
      </Modal.Header>
      <Modal.Body>
        {/* <h4>Centered Modal</h4>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p> */}
        
        
        <Row>
            <Col sm={12} lg={6} > <img src={Modal2Img} className="img-responsive Modal-img" ></img> </Col>
            <Col className='ModalTextCol'>
            {/* <Row><h3 style={{color:"black",textAlign:"center"}} className="ModalText">I am Product2</h3></Row> */}
            <br></br>
            <Row><h5 style={{color:"black",textAlign:"center"}} className="ModalText">With the best resources available for students in the curriculum, I guide students in all subjescts and help them prepare for one of the most important board exams of their careers. </h5></Row>
            <br></br>
            {/* <Row><h6 style={{color:"black",textAlign:"center"}} className="ModalText">test not knowing what is to be added pppppppppppp</h6> </Row> */}
            </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={props.onHide}>Close</Button> */}
      </Modal.Footer>
    </Modal>
  );
}

