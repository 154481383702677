import "./StandForComp.css";

//React Boostrap
import { Row, Col } from "react-bootstrap";

//Image
import StandForImg from './StandForImg.webp'

export default function StandForComp() {
  return (
    <div>
        <h3 className="StandForHeading">What We Stand For</h3>
      <Row style={{margin:"0"}}>
        <Col  id="StandForCol1" lg={6}>
          <h3 id="StandForPara">“Education is the key that unlocks the golden door to freedom.” <br></br>—George Washington Carver

          </h3>
        </Col>
        <Col id="StandForCol2" lg={6}  >
        <img  style={{width:"100%",height:"100%",objectFit:"cover"}}src={StandForImg}></img>
        </Col>
      </Row>
    </div>
  );
}
