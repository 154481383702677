import "./MaskFeatures.css";

//React BootSTrap
import { Row, Col, Card } from "react-bootstrap";

//Images
import WearingMask from "./WearingMask.png";
import MaskLayers from "./MaskLayers.png";
import WashingMachine from "./WashingMachine.png";

import GoalImg from './Goal.png';
import ClassroomImg from './Classroom.png';
import BooksImg from './Books.png';
import DataScience from './DatScience.png'

export default function MaskFeatures() {
  return (
    <div>
      <h3 className="MaskFeaturesHeading">Industries I Serve</h3>
      <Row style={{margin:"0"}}>
        <Col lg={4} sm={12}>
          <Card style={{backgroundColor:"#FDF8EC",border:"none"}} className="FeatureCard">
            <Card.Img
              variant="top"
              src={DataScience}
              style={{ width: "20%",margin:"auto",padding:"7rem 0" }}
            />
            <Card.Body>
              <Card.Title className="MaskFeaturesText">Technology</Card.Title>
              <Card.Text className="MaskFeatureDesc"  style={{marginBottom:"3rem"}}>
              As a coder and technician in data science and data analytics, I have a vast experience in this domain.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} sm={12}>
          <Card style={{backgroundColor:"#FE9C7A",border:"none"}} className="FeatureCard">
            <Card.Img
              variant="top"
              src={ClassroomImg}
              style={{ width: "20%",margin:"auto",padding:"7rem 0" }}
            />
            <Card.Body>
              <Card.Title className="MaskFeaturesText">Education</Card.Title>
              <Card.Text className="MaskFeatureDesc"  style={{marginBottom:"3rem"}}>
              As a coach & teacher,I have been working in the education industry for a long time. 
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} sm={12}>
          <Card style={{backgroundColor:"#FDF8EC",border:"none"}} className="FeatureCard">
            <Card.Img
              variant="top"
              src={BooksImg}
              style={{ width: "20%",margin:"auto",padding:"7rem 0" }}
            />
            <Card.Body>
              <Card.Title className="MaskFeaturesText">Coaching</Card.Title>
              <Card.Text className="MaskFeatureDesc" style={{marginBottom:"3rem"}}>
              I started Infinity Academy as a result of  alignment to guiding student pursue careers of their interest. 
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
