import './MainPicComp.css'

//Images
import MainPic from './main-container.jpg';
import MainPic1 from './MainPic1.jpg';

//
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

export default function MainPicComp(){
    return(
        <div id="ActualMainPicCont"  >
            <img  src={MainPic} className="ActualMainPic"></img>
            <div className='ActualMainPicText'>
                <h3 className='PicText'>Shubham Mittal</h3>
                <br></br>
                {/* <Link to='/#PopularMasksHeader' </Link> */}
                <Nav.Link href="/#PopularMasksHeader" ><Button className='MainPicBtn'>Know More</Button></Nav.Link>
          
            </div>
        </div>
    )
}