import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

//Css file
import "./NavbarComp.css";

function NavbarComp() {
  return (
    <Navbar bg="light" expand="lg" style={{ padding: "1.5rem 1.2rem" }}>
      {/* <Container style={{padding:"1.5rem 0",width:"100%"}}> */}
      <Navbar.Brand href="/" className="  navbarText navbarBrandText">
        Shubham Mittal
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {/* <Nav.Link href="#home" className='navbarText navbarLink'>Home</Nav.Link> */}
          <Nav.Link
            href="/contact"
            className="navbarText navbarLink"
            style={{ color: "black" }}
          >
            Contact
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      {/* </Container> */}
    </Navbar>
  );
}

export default NavbarComp;
