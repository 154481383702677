import "./ContactUsInquiry.css";

//React Boostrap
import { Col, Row, Button } from "react-bootstrap";

export default function ContactUsInquiry() {
  return (
    <div>
      <Row style={{ margin: "0", padding: "0" }}>
        <Col lg={4} className="ContactInquiryCol1" style={{ margin: "0" }}>
          <div id="InquirySection">
            <h3 className="InquiryText InquiryColouredText">Inquiries</h3>
            <br></br>
            <p className="InquiryText">
              For questions regarding our services you can also
              contact us by clicking on the Whatsapp link.
            </p>
          </div>
        </Col>
        <Col lg={8} className="ContactInquiryCol" style={{ margin: "0" }}>
          <div id="InquiryForm">
            <h3 className="InquiryFormText InquiryColouredText">
              Connect With Us{" "}
            </h3>
            <br></br>
            <form>
              {/* <Row style={{ margin: " 0",padding:"0" }}>
                <Col style={{ margin: "0.5rem 0" }} sm={12} lg={6} >
                  <h4 className="InquiryFormText InquiryColouredText lightText">First Name</h4>
                  <input type="text" required style={{ width: "100%" }}></input>
                </Col>
                <Col style={{ margin: "0.5rem 0" }} sm={12} lg={6}>
                  <h4 className="InquiryFormText InquiryColouredText lightText">Last Name</h4>
                  <input type="text" required style={{ width: "100%" }}></input>
                </Col>
              </Row>
              <Row style={{ margin: "0.5rem 0",padding:"0" }} >
                <Col style={{ margin: "0.5rem 0" }} sm={12} lg={6}>
                  <h4 className="InquiryFormText InquiryColouredText lightText">Email*</h4>
                  <input
                    type="email"
                    required
                    style={{ width: "100%" }}
                  ></input>
                  <br></br>
                </Col>
                <Col style={{ margin: "0.5rem 0" }}  >
                  <h4 className="InquiryFormText InquiryColouredText lightText">Subject</h4>
                  <input type="text" style={{ width: "100%" }}></input>
                </Col>
              </Row>
              <Row style={{ margin: "0",padding:"0" }}>
                <Col style={{ margin: "0" }}>
                  {" "}
                  <h3 className="InquiryFormText InquiryColouredText lightText">Leave us a message</h3>
                  <textarea style={{ width: "100%" }} rows='4'></textarea>
                </Col>
              </Row>
              <br></br> */}
              <Row style={{ margin: "0" }}>
                {/* <Col style={{paddingRight:"0"}}><button type="submit" className="FormSubmitButton InquiryFormText InquiryColouredText lightText" > Submit</button></Col> */}
                <Col style={{ paddingLeft: "0" }}>
                  <Button href="https://wa.me/919999198149" className="FormSubmitButton InquiryFormText InquiryColouredText lightText">
                    Whatsapp
                  </Button>
                </Col>
              </Row>
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
}
