import "./FooterComp.css";

//Boostrap imports
import { Row, Col } from "react-bootstrap";

//React Icons
import {BsFacebook} from 'react-icons/bs';
import {BsInstagram} from 'react-icons/bs'

export default function FooterComp() {
  return (
    <Row style={{ margin: "2rem 0",textAlign:"center"}} id="FooterDiv">
      <Col lg={9} style={{border:"1px solid rgb(0, 0, 0,0.5)"}}>
        <h3 className="FooterBrand">Shubham
Mittal</h3>
      </Col>
      <Col lg={3} style={{border:"1px solid rgb(0, 0, 0,0.5)"}}>
        <h3 className="FooterColouredText" style={{marginTop:"4rem",marginBottom:"2rem",fontSize:"2rem"}}>Contact us</h3>
        <Row style={{ margin: "0" }}>
          {/* <h4 className="FooterContactSection FooterLightText"><a href="mailto:someone@example.com">info@gmail.com</a></h4> */}
          <br></br>
          <h4 className="FooterContactSection FooterLightText">TelNo. +91 9999198149</h4>
          <br></br>
          <h4 className="FooterContactSection FooterLightText">
            {/* <a href="www.google.com" style={{marginRight:"10px",textDecoration:"none"}}><BsFacebook style={{color:"black"}}/></a>  */}
            <a href="https://instagram.com/infinity_officials_?igshid=YmMyMTA2M2Y=" style={{textDecoration:"none",color:"black"}}>Instagram<BsInstagram style={{color:"black",marginLeft:"10px"}}  /></a></h4>
          <br></br>
        </Row>
      </Col>
      <Row style={{margin:"0",padding:"0.8rem",textAlign:"center",border:"1px solid rgb(0, 0, 0,0.5)"}}>
        <h4 className="FooterContactSection">©️ 2022 Proudly created by <a href="https://www.atomicloops.com/">Atomic Loops</a></h4>
      </Row>
    </Row>
  );
}
