//Boostrap link
import "bootstrap/dist/css/bootstrap.min.css";

//Component Imports for Contacts
import NavbarComp from "./components/NavbarComp/NavbarComp";
import GetInTouchComp from "./components/GetInTouchComp/GetInTouchComp";
import ContactUsInquiry from "./components/ContactUsInquiry/ContactUsInquiry";
import FooterComp from "./components/FooterComp/FooterComp";

//Components for Home
import MainPicComp from "./components/MainPicComp/MainPicComp";
import PopularMasks from "./components/PopularMasks/PopularMasks";
import MaskFeatures from "./components/MaskFeatures/MaskFeatures";
import StandForComp from "./components/StandForComp/StandForComp";
import TagUsComp from "./components/TagUsComp/TagUsComp";

//Router Dom

import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <NavbarComp />
              <MainPicComp />
              <PopularMasks />
              <MaskFeatures />
              <StandForComp />
              {/* <TagUsComp /> */}
              <FooterComp />
            </>
          }
        />
        <Route
          path="/contact"
          exact
          element={
            <>
              <NavbarComp />
              <GetInTouchComp />
              <ContactUsInquiry />
              <FooterComp />
            </>
          }
        />
      </Routes>
    </div>
  );
}

export default App;

//  {/* <NavbarComp/>
// <MainPicComp/>
// <PopularMasks/>
// <MaskFeatures/>
// <StandForComp/>
// <TagUsComp/>
// <FooterComp/> */}
//       {/* { /* Contact */}
//       {/* <NavbarComp/>
//       <GetInTouchComp/>
//       <ContactUsInquiry/>
//       <FooterComp/> */}
